// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-category-js": () => import("./../../../src/layouts/category.js" /* webpackChunkName: "component---src-layouts-category-js" */),
  "component---src-layouts-post-js": () => import("./../../../src/layouts/post.js" /* webpackChunkName: "component---src-layouts-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-dla-inwestorow-js": () => import("./../../../src/pages/dla-inwestorow.js" /* webpackChunkName: "component---src-pages-dla-inwestorow-js" */),
  "component---src-pages-dla-poczatkujacych-js": () => import("./../../../src/pages/dla-poczatkujacych.js" /* webpackChunkName: "component---src-pages-dla-poczatkujacych-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kryptowoltaika-js": () => import("./../../../src/pages/kryptowoltaika.js" /* webpackChunkName: "component---src-pages-kryptowoltaika-js" */),
  "component---src-pages-o-xminer-js": () => import("./../../../src/pages/o-xminer.js" /* webpackChunkName: "component---src-pages-o-xminer-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-serwis-i-kolokacja-js": () => import("./../../../src/pages/serwis-i-kolokacja.js" /* webpackChunkName: "component---src-pages-serwis-i-kolokacja-js" */),
  "component---src-pages-sprzedaz-js": () => import("./../../../src/pages/sprzedaz.js" /* webpackChunkName: "component---src-pages-sprzedaz-js" */),
  "component---src-pages-uslugi-dodatkowe-js": () => import("./../../../src/pages/uslugi-dodatkowe.js" /* webpackChunkName: "component---src-pages-uslugi-dodatkowe-js" */)
}

