module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Xminer koparki kryptowalut","short_name":"Xminer","start_url":"/","background_color":"#111C3B","theme_color":"#111C3B","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a8b037a76efeb8a39ffd92e048801d2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7QW0XHF3SE"]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","scope":"html","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"interval":500,"timeout":30000,"web":[{"name":"Poppins","file":"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Xminer","short_name":"Xminer koparki kryptowalut","description":"Gotowe koparki kryptowalut w różnych konfiguracjach, hosting, serwis, know-how, kopanie kryptowalut z Xminer.","start_url":"/","background_color":"#111C3B","theme_color":"#F6F9FB","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a8b037a76efeb8a39ffd92e048801d2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
